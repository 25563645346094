import { lazy } from "../apps/mixins/lazy";

export default class VideoBlock {
    private USE_MOBILE_VIDEO_SOURCE_TRESHOLD = 768 as const;

    private videoElement?: HTMLVideoElement;
    private modelData: VideoBlockModel;

    constructor(element: HTMLVideoElement) {
        this.videoElement = element;

        if (!this.videoElement) return;

        const modelData = this.videoElement.dataset.modelData;

        if (!modelData) return;

        this.modelData = JSON.parse(modelData) as VideoBlockModel;
        this.init();
    }

    /**
     * Initializes the video block with a poster and the resize observer.
     */
    init = () => {
        if (!this.videoElement) return;
        this.initPoster();
        lazy(this.initVideoElement, this.videoElement, true);
        this.initResizeObserver();
    };

    /**
     * targetSource is set based on browser width and presence of relevant source
     */
    get targetSource() {
        const shoulUseMobileSource =
            window.innerWidth < this.USE_MOBILE_VIDEO_SOURCE_TRESHOLD;

        return shoulUseMobileSource && this.modelData.videoSourceMobile
            ? this.modelData.videoSourceMobile
            : this.modelData.videoSourceDesktop || "";
    }

    private getPathName = (url: string) => new URL(url).pathname;

    /**
     * Safe handling of triggering play of video, with a fallback of providing
     * controls if video cannot autoplay
     */
    private play = async () => {
        if (!this.videoElement) return;
        const videoElement = this.videoElement;

        const playVideo = async () => {
            try {
                await videoElement.play();
            } catch (error) {
                console.error("Error playing video:", error);
                videoElement.controls = true;
            }
        };

        if (this.videoElement.readyState >= 3) {
            await playVideo();
        } else {
            this.videoElement.addEventListener("loadedmetadata", playVideo, {
                once: true,
            });
        }
    };

    /**
     * Sets and loads the video source, configures the elements based on model
     * properties and handles autoplay
     */
    private initVideoElement = () => {
        if (!this.videoElement) return;

        //Only set src if it's not already set by initPoster
        if (!this.videoElement.src) {
            this.videoElement.src = this.targetSource;
        }

        this.videoElement.controls = !!this.modelData.controls;
        this.videoElement.loop = !!this.modelData.loop;

        if (this.modelData.autoplay) {
            this.videoElement.muted = true;
            this.play();
        }
    };

    /**
     * Sets the first frame of the video
     */
    private initPoster = () => {
        if (!this.videoElement) return;

        const videoElement = this.videoElement;
        videoElement.src = this.targetSource + "#t=0.1";
    };

    /**
     * Initializes the resize observer and sets up the event listener for the 'resize' event.
     * It also calls the `handleResize` method once initially to check the current viewport size.
     */
    private initResizeObserver = () => {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    };

    /**
     * Handles the resize event and updates the video source if the target source has changed.
     */
    private handleResize = () => {
        if (!this.videoElement || !this.videoElement.src) return;

        if (this.getPathName(this.videoElement.src) !== this.targetSource) {
            this.videoElement.src = this.targetSource;
            this.videoElement.load();
            this.play();
        }
    };

    /**
     * Removes the event listener for the 'resize' event if the hero block is destroyed.
     */
    destroy = () => {
        window.removeEventListener("resize", this.handleResize);
    };
}

interface VideoBlockModel {
    autoplay: boolean;
    loop: boolean;
    controls: boolean;
    videoSourceDesktop?: string;
    videoSourceMobile?: string;
}
