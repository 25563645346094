import { LineItemModel } from "../utils/API/Cart/cartAPISchema";
import {
    GoogleAnalytics4EventModel,
    GoogleAnalytics4EventModelSchema,
    Item,
} from "../utils/API/GoogleAnalytics/GoogleAnalyticsSchema";
import { handleGtmTracking } from "../utils/API/GTM";
import { LogErrorResponse } from "../utils/API/Logging/LoggingAPI";
import { Ga4Event, Ga4Events } from "./models/gtm.models";
import {
    CustomField,
    Product,
    ProductGroup,
    SearchResultRecommendList,
} from "./search.service";

export default class GtmService {
    static pushCheckoutEvent = async (
        eventName: Ga4Event,
        lineItem?: LineItemModel,
    ) => {
        const object = await this.getCheckoutEventModel(
            eventName,
            lineItem?.code,
        );
        handleGtmTracking(object);
    };

    static pushViewItemListEvent = async (
        itemListId: string,
        itemListName: string,
        isUserAuthenticated: boolean,
        searchResultRecommendList: SearchResultRecommendList,
        upsellAlgorithm?: string,
    ) => {
        const object = await this.getViewItemListEventModel(
            itemListId,
            itemListName,
            isUserAuthenticated,
            searchResultRecommendList,
            upsellAlgorithm,
        );

        if (!object) return;
        handleGtmTracking(object);
    };

    static pushSelectItemEvent = async (
        itemListId: string,
        itemListName: string,
        isUserAuthenticated: boolean,
        product: Product,
        productIndex?: number,
        upsellAlgorithm?: string,
    ) => {
        const object = await this.getSelectItemEventModel(
            itemListId,
            itemListName,
            isUserAuthenticated,
            product,
            productIndex,
            upsellAlgorithm,
        );

        if (!object) return;
        handleGtmTracking(object);
    };

    static pushViewPromotionEvent = (
        itemId: string,
        itemName: string,
        isUserAuthenticated: boolean,
        creativeName?: string,
        creativeSlot?: string,
    ) => {
        const object = this.getViewPromotionEvent(
            itemId,
            itemName,
            isUserAuthenticated,
            creativeName,
            creativeSlot,
        );

        if (!object) return;
        handleGtmTracking(object);
    };

    static pushSelectPromotionEvent = (
        itemId: string,
        itemName: string,
        isUserAuthenticated: boolean,
        linkHref?: string,
        creativeName?: string,
        creativeSlot?: string,
    ) => {
        const object = this.getSelectPromotionEvent(
            itemId,
            itemName,
            isUserAuthenticated,
            linkHref,
            creativeName,
            creativeSlot,
        );

        if (!object) return;
        handleGtmTracking(object);
    };

    static getCheckoutEventModel = async (
        eventName: string,
        lineItemCode?: string,
    ): Promise<GoogleAnalytics4EventModel> => {
        const url = `/api/tracking/checkoutevent${lineItemCode ? `?itemCode=${lineItemCode}` : ""}`;

        const response = await fetch(url.toString(), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            await LogErrorResponse(url, response);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const validatedData = GoogleAnalytics4EventModelSchema.parse(data);
        validatedData.event = eventName;

        return validatedData;
    };

    static getSelectItemEventModel = async (
        itemListId: string,
        itemListName: string,
        isUserAuthenticated: boolean,
        product: Product,
        productIndex?: number,
        algorithm?: string,
    ) => {
        const item = this.getItem(
            product,
            itemListId,
            itemListName,
            productIndex,
        );
        if (!item) return null;

        const googleAnalytics4EventModel: GoogleAnalytics4EventModel = {
            event: Ga4Events.SelectItem,
            logged_in_status: isUserAuthenticated,
            ecommerce: {
                item_list_id: itemListId,
                item_list_name: itemListName,
                upsell_algorithm: algorithm,
                items: [item],
            },
        };

        return googleAnalytics4EventModel;
    };

    static getViewItemListEventModel = async (
        itemListId: string,
        itemListName: string,
        isUserAuthenticated: boolean,
        searchResultRecommendList: SearchResultRecommendList,
        algorithm?: string,
    ) => {
        const items = this.getItems(
            itemListId,
            itemListName,
            searchResultRecommendList,
        );
        if (!items.length) return null;

        const googleAnalytics4EventModel: GoogleAnalytics4EventModel = {
            event: Ga4Events.ViewItemList,
            logged_in_status: isUserAuthenticated,
            ecommerce: {
                item_list_id: itemListId,
                item_list_name: itemListName,
                upsell_algorithm: algorithm,
                items: items,
            },
        };

        return googleAnalytics4EventModel;
    };

    static getViewPromotionEvent = (
        creativeName: string,
        creativeSlot: string,
        isUserAuthenticated: boolean,
        promotionId?: string,
        promotionName?: string,
    ) => {
        if (!creativeName || !creativeSlot) return null;

        const googleAnalytics4EventModel: GoogleAnalytics4EventModel = {
            event: Ga4Events.ViewPromotion,
            logged_in_status: isUserAuthenticated,
            ecommerce: {
                promotion_id: promotionId,
                promotion_name: promotionName,
                creative_name: creativeName,
                creative_slot: creativeSlot,
                items: [],
            },
        };

        return googleAnalytics4EventModel;
    };

    static getSelectPromotionEvent = (
        creativeName: string,
        creativeSlot: string,
        isUserAuthenticated: boolean,
        promotionDestination?: string,
        promotionId?: string,
        promotionName?: string,
    ) => {
        if (!creativeName || !creativeSlot) return null;

        const googleAnalytics4EventModel: GoogleAnalytics4EventModel = {
            event: Ga4Events.SelectPromotion,
            logged_in_status: isUserAuthenticated,
            ecommerce: {
                promotion_id: promotionId,
                promotion_name: promotionName,
                promotion_destination: promotionDestination,
                creative_name: creativeName,
                creative_slot: creativeSlot,
                items: [],
            },
        };

        return googleAnalytics4EventModel;
    };

    private static getItems = (
        itemListId: string,
        itemListName: string,
        searchResultRecommendList?: SearchResultRecommendList,
    ): Item[] => {
        if (searchResultRecommendList?.primaryList?.productGroups?.length === 0)
            return [];

        return (
            searchResultRecommendList?.primaryList?.productGroups?.map(
                (productGroup, productGroupIndex) => {
                    const product = productGroup.products[0];

                    if (!searchResultRecommendList.shopId) {
                        return this.getItem(
                            product,
                            itemListId,
                            itemListName,
                            productGroupIndex,
                        );
                    }

                    return this.getItem(
                        product,
                        searchResultRecommendList.shopId,
                        itemListName,
                        productGroupIndex,
                        [
                            searchResultRecommendList.shopId,
                            product.custom.Dept[0]?.label || "",
                            product.custom.Category[0]?.label || "",
                        ]
                    );
                },
            ) || []
        );
    };

    private static getItem = (
        product: Product,
        itemListId: string,
        itemListName: string,
        productGroupIndex?: number,
        categories: Array<string> = [],
    ): Item => {
        let item = {
            item_id: product.key,
            item_name: product.title,
            item_brand: product.brand,
            item_variant: product.custom.ColorName[0]?.label || "",
            price: product.sellingPrice.max,
            discount: product.listPrice.max - product.sellingPrice.max,
            item_list_id: itemListId,
            item_list_name: itemListName,
            department: product.custom.Dept[0]?.label || "",
            in_stock: product.inStock,
            index: productGroupIndex,
        } as Item;

        if (categories?.length > 0) {
            item.item_category = categories[0];
            if (categories.length > 1) item.item_category2 = categories[1];
            if (categories.length > 2) item.item_category3 = categories[2];
        }

        if (!categories) this.assignCategories(item, product.custom);

        return item;
    };

    private static assignCategories = (
        item: Item,
        custom: { [key: string]: Array<CustomField> },
    ) => {
        if (!custom?.Category) return;

        if (custom.Category.length > 0)
            item.item_category = custom.Category[0].label;
        if (custom.Category.length > 1)
            item.item_category2 = custom.Category[1].label;
        if (custom.Category.length > 2)
            item.item_category3 = custom.Category[2].label;
        if (custom.Category.length > 3)
            item.item_category4 = custom.Category[3].label;
        if (custom.Category.length > 4)
            item.item_category5 = custom.Category[4].label;

        return item;
    };
}
