export default class vimeoBlock {
    constructor(element) {
        const videoId = element.dataset.videoId;
        const autostart = element.dataset.videoAutostart;
        const background = element.dataset.videoBackground;
        const mute = element.dataset.videoMuted;
        const muted = autostart === true ? true : mute === true ? true : false;

        const options = {
            width: 1080,
            autopause: true,
            loop: true,
            autoplay: autostart,
            background: background,
            muted: muted,
            title: false,
            byline: false,
            id: videoId,
        };

        window.addEventListener("load", () => {
            new window.Vimeo.Player("video-" + videoId, options);
        });
    }
}
